@import url("https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto&display=swap");

.healthcare-container {
  background-color: #fff;
  scroll-margin-top: 10vh;
  padding-top: 20px;
  padding-bottom: 10px;
}

.healthcare {
  background-color: #eeeeee;
}

.healthcare:last-child {
  margin-bottom: 25px;
}

.healthcare-container-header {
  margin: 0;
  font-size: 32px;
  font-weight: 400;
  text-align: center;
}

.healthcare-description-use-case-header {
  padding: 30px 10px 15px 15px;
  margin: 0;
  font-size: 24px;
  background-color: #ffffff;
  color: rgba(70, 45, 233, 0.832);
  font-weight: 700;
}

.healthcare-description {
  text-align: center;
}

.healthcare-description-header {
  font-weight: 600;
  font-size: 18px;
  color: grey;
}

.healthcare-description-item {
  text-align: left;
  color: #2f2f2f;
  list-style-type: disc;
  padding-bottom: 10px;
  font-size: 15px;
  font-weight: 500;
}

@media (min-width: 1380px) {
  .healthcare-container {
    padding: 20px 10%;
  }
}
