.main-container {
  box-sizing: border-box;
  height: 90vh;
  background-color: #02162d;
  text-align: center;
  scroll-margin-top: 10vh;
}

.header-name {
  height: 25vh;
}

.header-name h2 {
  margin-top: 0;
  height: 33%;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 2.5rem;
  line-height: 4rem;
  color: #fff;
  text-align: center;
  letter-spacing: -1.375px;
  margin-bottom: 0;
}

.header-name p {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  text-align: center;
  color: #ffffff;
  opacity: 0.8;
  height: 15%;
  padding: 0 5px;
}

.button-trial {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  background-color: #5567ff;
  text-align: center;
  letter-spacing: -0.16px;
  margin-right: 10px;
  color: #ffffff;
  background: #5567ff;
  border-radius: 5px;
  height: 2.75rem;
}

.button-trial:hover {
  background-color: #485aff !important;
}

.button-trial:focus {
  transform: scale(0.97);
}

.button-video {
  background: #02162d;
  border: 2px solid #7a7a7a;
  border-radius: 5px;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: -0.16px;
  margin-left: 0.75rem;
  height: 2.75rem;
}

.button-video:hover {
  background: #5567ff !important;
}

.image-1 {
  width: 90%;
  height: 90%;
  background-image: url("../../../../assets/img/Chronologies.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  margin-bottom: 2.25rem;
}

.image-3 {
  width: 90%;
  height: 90%;
  background-image: url("../../../../assets/img/Breakdown.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  margin-bottom: 2.25rem;
}
.image-4 {
  width: 90%;
  height: 90%;
  background-image: url("../../../../assets//img/TimeInterval.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  margin-bottom: 2.25rem;
}

.video-trial-buttons {
  height: 35%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 480px) {
  .main-container {
    height: 40vh;
  }
  .video-trial-buttons {
    flex-direction: column;
    margin-top: 50px;
    justify-content: center !important;
    align-items: center;
  }
  .carousel {
    display: none;
  }

  .button-video,
  .button-trial {
    z-index: 1;
    margin: 0;
  }
  .button-trial {
    margin-bottom: 15px;
    margin-top: 15px;
  }
}

@media (max-width: 837px) {
  .video-trial-buttons {
    padding-top: 10px;
  }
}
