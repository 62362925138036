@import url("https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

.contact-container {
  text-align: center;
  scroll-margin-top: 10vh;
  background-color: #e5eaf5;
}

.contact-container h2 {
  margin-top: 0;
  padding-top: 2.5rem;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  text-align: center;
  letter-spacing: -0.5px;
  color: #0f2137;
  margin-bottom: 5px;
}

.contact-container p {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #02073e;
  opacity: 0.7;
  margin: 5px 5px;
}

.input {
  display: flex;
  justify-content: space-between;
  transition: 0.1s all ease-in-out;
}

.input input {
  width: 100%;
  border: 1px solid black;
  height: 30px;
  margin-bottom: 10px;
  background: #ffffff;
  border: 1px solid #cccbcb;
  padding: 0 10px;
  border-radius: 5px;
}

.input #company-input {
  width: 100%;
}

.input #textarea {
  width: 100%;
  height: 25vh;
  background: #ffffff;
  border: 1px solid #cccbcb;
  border-radius: 5px;
  padding: 10px 10px;
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
}

::placeholder {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #cccbcb;
}

#button-submit {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.16px;
  background-color: #5567ff;
  height: 36px;
  margin: 15px auto;
}

.ant-col {
  justify-content: center;
}

input:focus,
#textarea:focus {
  outline: none;
  border: 2px solid #5567ff;
  transition: 0.1s all ease-in-out;
}

@media (min-width: 1380px) {
  .contact-container {
    padding: 0 20%;
  }
}

textarea {
  resize: none;
}
