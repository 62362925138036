@import url("https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto&display=swap");

.process-mining-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  scroll-margin-top: 10vh;
  background-color: #fff;
}

.description {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  background-color: #eeeeee;
  padding-left: 5%;
  padding-right: 5%;
}

.graphics {
  display: flex;
  align-items: center;
  justify-content: center;
  scroll-margin-top: 10vh;
}

.description-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.description-left p,
a {
  font-size: 16px;
  line-height: 36px;
  font-family: "DM Sans", sans-serif;
  font-weight: 400;
  margin-bottom: 5px;
  color: #02073e;
}

.description-left a {
  color: #5567ff;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 1px;
}

.description-left h2 {
  font-size: 2rem;
  letter-spacing: -1px;
  font-weight: 500;
  color: #0f2137;
  margin-bottom: 15px;
  margin-top: 8px;
}

.description-right {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-image: url("../../../../assets/img/ToolDemoVisuals.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.graphics-left {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%;
  background-image: url("../../../../assets/img/illustration.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  margin-top: 25px;
  margin-bottom: 15px;
}

.graphics-right {
  text-align: center;
  text-align: left;
  padding: 10px 25px;
}

.graphics-right p {
  font-size: 16px;
  line-height: 36px;
  font-family: "DM Sans", sans-serif;
  font-weight: 400;
  margin-bottom: 5px;
  color: #02073e;
}

.graphics-right a {
  color: #5567ff;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.graphics-right a:hover {
  color: #8591fe;
}

.graphics-right h2 {
  font-size: 32px;
  letter-spacing: -1px;
  font-weight: 500;
  color: #0f2137;
  margin-bottom: 15px;
  margin-top: 8px;
  line-height: 55px;
}

.playIcon {
  margin-left: 5px;
}

@media (max-width: 991px) {
  .description-right,
  .graphics-left {
    display: none;
  }
  .graphics-right h2,
  .description-left h2 {
    width: 80%;
    font-size: 28px;
  }
  #description-right {
    display: none;
  }
}

@media (min-width: 1380px) {
  .description,
  .graphics {
    padding-left: 0 23%;
    height: 450px;
    width: 100%;
  }
  .graphics-right {
    padding-right: 25%;
  }
  .description-left {
    padding-left: 25%;
  }

  .description-right {
    height: 95%;
  }
  .graphics-left {
    height: 95%;
  }
}
