@import url("https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto&display=swap");

.faq {
  width: 100%;
  scroll-margin-top: 10vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
}
.text-center {
  text-align: center;
}
.flex-row {
  display: flex;
}
.faq-benefits-items {
  padding: 0 5px;
  font-size: 16px;
}
.faq-header {
  text-align: center;
}

.faq-buttons {
  height: 8vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
}

.faq-header h2 {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 50px;
  text-align: center;
  letter-spacing: -0.5px;
  margin-bottom: 0;
  color: #0f2137;
}

.faq-header-benefits {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 50px;
  text-align: center;
  letter-spacing: -0.5px;
  margin-bottom: 25px;
  margin-top: 25px;
  color: #0f2137;
}
.faq-header-benefits p {
  margin: 0;
}
.benefit-description {
  margin: 5px;
  padding-left: 5px;
}
.faq-questions {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #eeeeee;
}

.ant-row {
  width: 100%;
  justify-content: space-around;
}

.ant-col {
  justify-content: center;
  align-items: center;
  margin-left: 0;
}

.faq-questions h2 {
  padding-right: 0px;
}

.question {
  border-bottom: 1px solid black;
  width: 100%;
}

.question h3 {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.5px;
  color: #0f2137;
}

.question p {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  opacity: 0.7;
  color: #343d48;
}

.faq-header p {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  color: #02073e;
  opacity: 0.7;
  margin-top: 5px;
}

#button-questions {
  background: #d6e6f3;
  border-radius: 5px;
  color: #02073e;
}

#button-questions span {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  color: #738295;
}

#button-questions span:hover {
  opacity: 1;
  color: #02073e;
}

@media (min-width: 1380px) {
  .faq-questions {
    padding: 0 18%;
  }
}
