@import url("https://fonts.googleapis.com/css2?family=Prosto+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto&display=swap");

.container-fluid {
  position: sticky;
  top: 0;
  overflow: hidden;
  z-index: 99;
}

#companyName {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  color: #e9e7e7;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 36px;
  display: flex;
  align-items: center;
  opacity: 0.8;
  margin-left: 5px;
}

#companyName:hover {
  cursor: pointer;
  color: #fff;
}

.header {
  background-color: #02162d;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 10vh;
}

.nav-links {
  width: 800px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: #02162d;
  color: #fff !important;
  font-family: Roboto;
  font-weight: 300;
}

.nav-link {
  color: #fff !important;
}

.nav-links a {
  color: #fff;
}

.nav-link a:hover {
  color: #1890ff !important;
  text-decoration: #8ca6fc underline !important;
  text-underline-offset: 10px;
}

#use-cases {
  font-family: Roboto;
  font-weight: 300;
  font-size: 16px;
}

.logo-container {
  color: #ffffff;
  margin-right: 3%;
}

.button-container {
  margin-left: 3%;
}
.button-register {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.16px;
  background-color: #5567ff;
  height: 42px;
}

.button-register:hover {
  background-color: #485aff !important;
}

.logo-container {
  display: flex;
  align-items: center;
  height: 10vh;
}

.logo-container a {
  margin-bottom: 0;
}

.logo {
  width: 32px;
  height: 32px;
  background-image: url("../../assets/logo/Baby\ Blue\ Logo\ \(SVG\).svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.logo:hover {
  cursor: pointer;
}

@media (min-width: 1161px) {
  .drawer {
    display: none;
  }
  .logo-container {
    margin-right: 3%;
  }

  .button-container {
    margin-left: 3%;
  }

  .nav-links {
    width: 750px;
  }

  .nav-links a {
    font-size: 16px;
  }
  #companyName {
    margin-right: 0;
    padding: 0;
  }
}

@media (max-width: 1160px) {
  .logo-container {
    display: none;
  }
  .nav-links {
    display: none;
  }
  .button-container {
    display: none;
  }

  .drawer-content {
    display: flex;
    flex-direction: column;
  }

  .drawer-content .nav-link {
    color: #02162d !important;
  }

  .logo {
    height: 48px;
    width: 48px;
    transition: 1s all ease;
  }

  .logo:hover {
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
  }
}

@media (max-width: 780px) {
  .logo-container {
    margin-right: 1%;
  }

  .button-container {
    margin-left: 1%;
  }

  .nav-links {
    width: 700px;
  }

  .nav-links a,
  .ant-space-item {
    font-size: 16px;
  }
  .drawer-content {
    width: 250px;
  }
}

@media (max-width: 460px) {
  .drawer-component {
    width: 300px !important;
    float: right;
  }
}

@media (min-width: 460px) {
  .drawer-component {
    width: 300px !important;
    float: right;
  }
}

@media (min-width: 1380px) {
  .header {
    justify-content: space-around;
  }

  .nav-links {
    width: 850px;
  }
  .nav-links a,
  .ant-space-item {
    font-size: 18px;
  }
  .logo-container {
    margin-left: 8%;
  }
  .button-container {
    margin-right: 8%;
  }
}

/* .ant-dropdown-menu-submenu-vertical:hover,
.ant-dropdown-menu-submenu-active {
  background-color: #bbedff;
} */
